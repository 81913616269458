import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

export const IS_CACHE_ENABLED = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<unknown>> = new Map();

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isCachable = request.method === 'GET' && request.context.get(IS_CACHE_ENABLED) === true;
    const cachedResponse: HttpResponse<unknown> | undefined = this.cache.get(request.url);

    if (isCachable) {
      if (cachedResponse) {
        return of(cachedResponse.clone());
      } else {
        return next.handle(request).pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              this.cache.set(request.url, event);
            }
          })
        );
      }
    }

    return next.handle(request);
  }
}
